<template>
  <SupportPageTemplate
    bannerSrc="/assets/image/faq_banner.jpg"
    :pageTitle="$t('label.shareYourCookingJourney')"
    :pageSubtitle="$t('label.beOurCreatorPartner')"
    color="secondary"
    :isPDF="true"
    topic="faqs"
  ></SupportPageTemplate>
</template>

<script>
  import SupportPageTemplate from '@/views/components/support/SupportPageTemplate';

  export default {
    name: 'FAQ',
    components: {
      SupportPageTemplate
    }
  };
</script>

<style></style>
